<template>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="card dveb-white-card">
                <h1>map</h1>
            </div>
        </div>
        <div class="col-md-6 col-12">
            <div class="card dveb-white-card">
                <b-tabs content-class="mt-3 " class="nav nav-tabs nav-tabs-line">
                    <b-tab title="First">
                        <div class="tab-area">
                            <div class="tab-content">
                                <img src="/image/image-150.png" alt="">
                                <div class="content col">
                                    <h4>Bünyamin Ceyhun Köse</h4>
                                    <b-button variant="primary">Profile Git</b-button>
                                </div>
                            </div>
                            <div class="tab-content">
                                <img src="/image/image-150.png" alt="">
                                <div class="content col">
                                    <h4>Bünyamin Ceyhun Köse</h4>
                                    <b-button variant="primary">Profile Git</b-button>
                                </div>
                            </div>
                            <div class="tab-content">
                                <img src="/image/image-150.png" alt="">
                                <div class="content col">
                                    <h4>Bünyamin Ceyhun Köse</h4>
                                    <b-button variant="primary">Profile Git</b-button>
                                </div>
                            </div>
                            <div class="tab-content">
                                <img src="/image/image-150.png" alt="">
                                <div class="content col">
                                    <h4>Bünyamin Ceyhun Köse</h4>
                                    <b-button variant="primary">Profile Git</b-button>
                                </div>
                            </div>
                            <div class="tab-content">
                                <img src="/image/image-150.png" alt="">
                                <div class="content col">
                                    <h4>Bünyamin Ceyhun Köse</h4>
                                    <b-button variant="primary">Profile Git</b-button>
                                </div>
                            </div>
                            <div class="d-flex w-full mt-2">
                                <a href="" class="ml-auto">Tümünü Gör</a>
                            </div>

                        </div>
                    </b-tab>
                    <b-tab title="Second">
                        <p>I'm the second tab</p>
                    </b-tab>
                    <b-tab title="Disabled">
                        <p>I'm a disabled tab!</p>
                    </b-tab>
                </b-tabs>

            </div>
        </div>

    </div>
</template>
<script>

export default {
    name: 'map-and-customer',
};

</script>

<style lang="scss">
.nav-tabs {
    border-bottom: 0 !important;
    div {
        width: 100%;

        li {
            flex: 1;

            a {
                display: flex;
                justify-content: center;
                margin: 0 auto !important;
                font-size: 1rem;
                color: #102640 !important;
                border-bottom: 5px solid #102640 !important;

                &.active {
                    border-bottom: 5px solid #3F98FF !important;

                }
            }
        }
    }
}

.tab-content {
    width: 100%;
}

.tab-area {
    display: flex;
    flex-direction: column;

    .tab-content {
        display: flex;
        align-items: center;

        img {
            background-color: red;
            border-radius: 50%;
            margin-right: 3rem;
            border: 2px solid #3F98FF;
        }

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}</style>